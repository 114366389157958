import React from "react"
import Layout from "../components/Layout"
import Resume from "../components/Resume"

const resume = () => {
  return (
    <Layout>
      <Resume />
    </Layout>
  )
}

export default resume
